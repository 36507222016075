<template>
  <div class="bg-white rounded h-full px-25 py-25 md:px-40 md:py-40">
    <h1 class="text-dark-cl-20 text-1xl sm:text-3xl mb-16">Change Password</h1>
    <p class="text-grey-fp-60 text-sm sm:text-base mb-44">
      In order to protect your account, please update your password.
    </p>

    <form @submit.prevent="onSubmit">
      <AppInput
        v-model="v$.newPassword.$model"
        name="newPassword"
        label="New Password"
        type="password"
        class="mb-25"
        :size="screen.width < 640 ? 'medium' : 'large'"
        use-hint
        :hintOptions="hintOptions"
        :error="errorMessages.newPassword"
        @focus="clearErrorField('newPassword')"
        @blur="validateField('newPassword')"
      />

      <AppInput
        v-model="v$.confirmPassword.$model"
        name="confirmPassword"
        label="Re-enter Your New Password"
        type="password"
        :size="screen.width < 640 ? 'medium' : 'large'"
        :error="errorMessages.confirmPassword"
        @focus="clearErrorField('confirmPassword')"
        @blur="validateField('confirmPassword')"
      />
    </form>
  </div>

  <!-- D E S K T O P  A C T I O N S -->
  <div class="hidden sm:flex items-center justify-center mt-40">
    <AppButton
      type="primary"
      custom-class="px-40 py-10 mr-5"
      :size="screen.width < 640 ? 'medium' : 'large'"
      @click="onSubmit"
    >
      Change password
    </AppButton>

    <AppButton
      type="secondary"
      custom-class="px-40 py-10 ml-5"
      :size="screen.width < 640 ? 'medium' : 'large'"
      @click="onCancel"
    >
      Cancel
    </AppButton>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, toRefs, computed, onMounted } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { sameAs, helpers } from '@vuelidate/validators';
  import { useToast } from "vue-toastification";
  import { useRouter, useRoute } from 'vue-router';
  import { routesNames } from '@/router';
  import { useScreen } from 'vue-screen';
  import { Auth } from 'aws-amplify';

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppInput from '@/components/stateless/AppInput.vue';

  import { IErrorFields, IPasswords } from '@/types';
  import { signUpValidationRules } from './auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'CreateNewPassword',

    components: { AppButton, AppInput },

    setup() {
      const toast = useToast();
      const router = useRouter();
      const route = useRoute();
      const screen = useScreen();

      const state = reactive({
        userName: '',
        passwords: {
          newPassword: '',
          confirmPassword: '',
        } as Partial<IPasswords>,
        errorMessages: {
          newPassword: '',
          confirmPassword: ''
        } as IErrorFields
      });

      const { errorMessages, passwords } = toRefs(state);

      const rules = computed(() => {
        return {
          newPassword: signUpValidationRules.password,
          confirmPassword: {
            ...signUpValidationRules.password,
            sameAs: helpers.withMessage('Password doesn\'t matched', sameAs(passwords.value.newPassword))
          }
        };
      });

      const v$ = useVuelidate(rules, passwords);

      const hintOptions = computed<any>(() => [
        { message: 'At least one Capital letter', matched: !v$.value.newPassword.containsUppercase.$invalid },
        { message: '8 characters minimum', matched: !v$.value.newPassword.minLength.$invalid && v$.value.newPassword.$model },
        { message: 'At least one Number', matched: !v$.value.newPassword.containsNumber.$invalid }
      ]);

      function onCancel() {
        router.push({ name: routesNames.login });
      }

      function checkParams() {
        const { email } = route.params;

        if (email) {
          state.userName = email as string;
        } else {
          router.push({ name: routesNames.login });
        }
      }

      async function loginUser() {

        return authService.logIn({ 
            email: state.userName, 
            password: passwords.value.confirmPassword as string, 
            keepSignIn: true 
          })
          .then((res: any) => {
            vuex.setUser(res);
            router.push({ name: routesNames.adminFamilies });
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }

      async function changePassword() {
        return Auth.completeNewPassword(
          vuex.user,
          passwords.value.confirmPassword as string,
          {
            phone_number: '+10000000000'
          }
        )
          .then(() => {
            toast.success('Password has beed updated');

            return loginUser();
          })
          .catch((error: any) => {
            if (error.code === 'NotAuthorizedException') {
              errorMessages.value.currentPassword = 'Incorrect password';
            } else {
              toast.error(error);
            }
          });
      }

      async function onSubmit() {
        if (await v$.value.$validate()) { changePassword(); }
        else { handleSetErrors(v$.value.$errors, errorMessages.value); }
      }

      onMounted(() => checkParams());

      return {
        errorMessages,
        v$,
        hintOptions,
        screen,

        onCancel,
        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages.value),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages.value),
      };
    }
  });
</script>

<style lang="scss" scoped>
  .mobile-actions {
    @apply fixed bottom-0 right-22 left-22 
          flex sm:hidden items-center justify-center 
          bg-white z-5 py-14 px-14 rounded-t-5;

    box-shadow: 0 -4px 15px 0 rgba(0, 0, 0, 0.05);
  }
</style>
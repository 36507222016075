
  import { defineComponent, reactive, toRefs, computed, onMounted } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { sameAs, helpers } from '@vuelidate/validators';
  import { useToast } from "vue-toastification";
  import { useRouter, useRoute } from 'vue-router';
  import { routesNames } from '@/router';
  import { useScreen } from 'vue-screen';
  import { Auth } from 'aws-amplify';

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppInput from '@/components/stateless/AppInput.vue';

  import { IErrorFields, IPasswords } from '@/types';
  import { signUpValidationRules } from './auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'CreateNewPassword',

    components: { AppButton, AppInput },

    setup() {
      const toast = useToast();
      const router = useRouter();
      const route = useRoute();
      const screen = useScreen();

      const state = reactive({
        userName: '',
        passwords: {
          newPassword: '',
          confirmPassword: '',
        } as Partial<IPasswords>,
        errorMessages: {
          newPassword: '',
          confirmPassword: ''
        } as IErrorFields
      });

      const { errorMessages, passwords } = toRefs(state);

      const rules = computed(() => {
        return {
          newPassword: signUpValidationRules.password,
          confirmPassword: {
            ...signUpValidationRules.password,
            sameAs: helpers.withMessage('Password doesn\'t matched', sameAs(passwords.value.newPassword))
          }
        };
      });

      const v$ = useVuelidate(rules, passwords);

      const hintOptions = computed<any>(() => [
        { message: 'At least one Capital letter', matched: !v$.value.newPassword.containsUppercase.$invalid },
        { message: '8 characters minimum', matched: !v$.value.newPassword.minLength.$invalid && v$.value.newPassword.$model },
        { message: 'At least one Number', matched: !v$.value.newPassword.containsNumber.$invalid }
      ]);

      function onCancel() {
        router.push({ name: routesNames.login });
      }

      function checkParams() {
        const { email } = route.params;

        if (email) {
          state.userName = email as string;
        } else {
          router.push({ name: routesNames.login });
        }
      }

      async function loginUser() {

        return authService.logIn({ 
            email: state.userName, 
            password: passwords.value.confirmPassword as string, 
            keepSignIn: true 
          })
          .then((res: any) => {
            vuex.setUser(res);
            router.push({ name: routesNames.adminFamilies });
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }

      async function changePassword() {
        return Auth.completeNewPassword(
          vuex.user,
          passwords.value.confirmPassword as string,
          {
            phone_number: '+10000000000'
          }
        )
          .then(() => {
            toast.success('Password has beed updated');

            return loginUser();
          })
          .catch((error: any) => {
            if (error.code === 'NotAuthorizedException') {
              errorMessages.value.currentPassword = 'Incorrect password';
            } else {
              toast.error(error);
            }
          });
      }

      async function onSubmit() {
        if (await v$.value.$validate()) { changePassword(); }
        else { handleSetErrors(v$.value.$errors, errorMessages.value); }
      }

      onMounted(() => checkParams());

      return {
        errorMessages,
        v$,
        hintOptions,
        screen,

        onCancel,
        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages.value),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages.value),
      };
    }
  });
